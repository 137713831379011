import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { buildText } from "@tvg/atomic-ui/_atom/Text";
import { fontNormal, fontBold } from "@tvg/atomic-ui/_static/Typography";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: ${buildColor("white", "100")};
  border-radius: 4px;
  padding: 12px;
  font-family: ${fontNormal};
`;

export const Title = styled.h1<{ type: string }>`
  color: ${buildColor("grey", "900")};
  text-align: center;
  font-size: ${({ type }) => (type === "success" ? "20px" : "16px")};
  margin-bottom: 12px;
`;

export const Message = styled.div<{ type: string; fullPage: boolean }>`
  padding-bottom: 20px;
  line-height: 24px;
  color: ${buildColor("grey", "800")};
  text-align: center;
  ${({ type, fullPage }) =>
    (type === "success" || fullPage) &&
    css`
      margin-bottom: auto;
    `};
`;
export const MessageParagraph = styled.p`
  a {
    display: inline !important;
    vertical-align: baseline;
  }
`;

export const MessageText = styled.p`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  white-space: pre-wrap;
  padding: 0 12px;

  nobr {
    display: inherit;
    white-space: pre-wrap;
  }
`;

export const IconContainer = styled.div<{ type: string; fullPage: boolean }>`
  padding-top: 8px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  ${({ type, fullPage }) =>
    (type === "success" || fullPage) &&
    css`
      margin-top: auto;
    `};
`;

export const ButtonStyled = styled(Button)<{ type: string }>`
  height: auto;
  margin-bottom: 8px;
  padding: 14px;
  line-height: 18px;
  font-size: 14px;
  ${({ type }) =>
    type !== "tertiary" &&
    css`
      box-shadow:
        0 2px 4px rgba(17, 43, 68, 0.06),
        0 1px 2px rgba(17, 43, 68, 0.08);
    `}

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const InnerContainer = styled.div`
  background-color: ${buildColor("white", "100")};
  display: flex;
  padding: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-self: center;
  border: 1px solid ${buildColor("blue", "100")};
  margin-top: 40px;
  border-radius: 2px;
  text-align: left;
`;

export const InnerTitle = styled.h2<{
  isSuccess?: boolean;
}>`
  font-family: ${fontBold};
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 18px;
  font-size: 14px;
  color: ${({ isSuccess }) =>
    isSuccess ? buildColor("green", "500") : buildColor("grey", "900")};
`;

export const InnerList = styled.ul`
  padding-left: 26px;
  padding-bottom: 20px;
`;
export const ListItem = styled(
  buildText({
    tag: "li",
    fontWeight: 400,
    color: buildColor("grey", "800")
  })
)`
  line-height: 18px;
  list-style: disc;
  padding: 2px 0;
  font-size: 14px;
`;

export const ListItemComplete = styled(ListItem)`
  color: ${buildColor("green", "500")};
  display: flex;
  align-items: center;
`;

export const ListIconContainer = styled.div`
  position: relative;
  left: 0;
  align-items: center;
  display: flex;

  > div {
    position: absolute;
    right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;
