import React from "react";

import { getPaymentMethodIconName } from "@tvg/wallet/src/utils";
import { get } from "lodash";
import { getPawsContent } from "@tvg/sh-lib-paws/utils/content";

import { State } from "@tvg/sh-lib-paws/redux/slices/walletSlice";
import { PaymentSelectorWrapper } from "./styled-components";

export const getChkPayment = (otherPm) =>
  otherPm.find((item) => item.paymentType === "CHK");

export const selectButtonTextFromPaws = (state) => {
  const content = getPawsContent(state);
  return get(
    content,
    "paymentMethodSelectorModal.buttonText",
    "Add Payment Method"
  );
};

export const transformText = (text) =>
  text ? text.trim().replace(/\s/g, "-").toLowerCase() : "";

export const getIdentifier = (title, description) =>
  `${transformText(title)}-${transformText(description)}`;

export const renderChkCard = (
  shouldRenderChkCard,
  otherMethods,
  selectedMethod,
  device,
  onClick
) => {
  if (!shouldRenderChkCard) return null;

  const chk = getChkPayment(otherMethods);

  if (!chk) return null;

  const method =
    chk.paymentTypeOptions && chk.paymentTypeOptions.length > 0
      ? chk?.paymentTypeOptions[0]
      : null;

  if (!method) return null;

  const parsedMethod = {
    ...method,
    description: null,
    title: "Check",
    paymentType: "CHK"
  };

  const identifier = getIdentifier(
    parsedMethod.title,
    parsedMethod.description
  );

  return (
    <PaymentSelectorWrapper
      hasDescription={!!parsedMethod.description}
      key="paymentSelector-0"
      variant="check"
      title={parsedMethod.title}
      description={parsedMethod.description}
      isSelected={selectedMethod === identifier}
      device={device}
      paymentType={parsedMethod.paymentType}
      iconName={getPaymentMethodIconName(parsedMethod.paymentType)}
      onClick={() => onClick(parsedMethod, identifier)}
      qaLabel={`payment-selector-${parsedMethod.title}`}
    />
  );
};
