import styled, { css } from "styled-components";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px;
  box-shadow: 0 1px 2px ${buildColor("blue", "000")};
  overflow: hidden;
`;

export const Button = styled.button`
  background-color: ${buildColor("white", "100")};
  border: none;
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 18px;
  height: 46px;
  min-width: 62px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${buildColor("grey", "900")};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${buildColor("grey", "700")};
      background-color: ${buildColor("blue", "000")};
    `}

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }

  &:not(:first-child) {
    border-left: 1px solid ${buildColor("blue", "100")};
  }
`;
