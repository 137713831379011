import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { Icon } from "@tvg/design-system";
import { Button, type ButtonVariant } from "@tvg/design-system/web";
import { getPromosOnboardingWalletSuccessHeaders } from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { renderIcon, onClickAction } from "./utils";
import {
  Container,
  ButtonStyled,
  Message,
  Title,
  IconContainer,
  MessageParagraph,
  MessageText,
  InnerContainer,
  InnerTitle,
  InnerList,
  ListItem,
  ListItemComplete,
  ListIconContainer,
  ButtonContainer
} from "./styled-components";
import type { Props } from "./types";
import { ButtonAction } from "../../types";

const MessagePanel = ({
  icon,
  title,
  fullPage = false,
  description,
  actions,
  variant = "warning",
  className = "",
  bodyChild,
  retryCount,
  bonusAmount,
  hasMatchingProposition = false,
  userHasBetPlaced = false,
  isUserInPromoOnboarding = false,
  promoOnboardingStepsNumber,
  promoOnboardingSteps,
  promoOnboardingActions
}: Props) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const promoOnboardingStepsToShow =
    isUserInPromoOnboarding &&
    !!promoOnboardingStepsNumber &&
    promoOnboardingSteps &&
    promoOnboardingSteps.filter(
      (_step, index) => index <= promoOnboardingStepsNumber
    );

  const promoOnboardingHeaders = useSelector(
    getPromosOnboardingWalletSuccessHeaders
  );

  const promoOnboardingHeader =
    promoOnboardingStepsNumber === 1
      ? get(
          promoOnboardingHeaders,
          "0",
          `Your additional ${bonusAmount} will be paid as wagering credit
  to your account within 24 hours.`
        )
      : get(promoOnboardingHeaders, "1", "To redeem your promotion:");

  const renderPromoOnboardingHeader = () => {
    const isSuccess = promoOnboardingStepsNumber === 1;
    const replacedHeader = promoOnboardingHeader.replace(
      "{bonusAmount}",
      bonusAmount
    );

    return <InnerTitle isSuccess={isSuccess}>{replacedHeader}</InnerTitle>;
  };

  useEffect(() => {
    setTimeout(() => {
      setHasBeenClicked(false);
    }, [300]);
  }, [retryCount]);

  return (
    <Container className={className} data-qa-label="message-panel">
      <IconContainer
        data-qa-label={`message-panel-${variant}-icon-container`}
        type={variant}
        fullPage={fullPage}
      >
        {icon || renderIcon(variant)}
      </IconContainer>
      <Title data-qa-label="message-panel-title" type={variant}>
        {title}
      </Title>
      <Message
        data-qa-label="message-panel-content-container"
        type={variant}
        fullPage={fullPage}
      >
        {typeof description === "string" ? (
          // eslint-disable-next-line react/no-danger
          <MessageParagraph dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          description.map((text) => (
            // eslint-disable-next-line react/no-danger
            <MessageText
              key={text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ))
        )}
      </Message>
      {!!bodyChild && bodyChild}
      {isUserInPromoOnboarding &&
        !userHasBetPlaced &&
        hasMatchingProposition &&
        !!promoOnboardingStepsToShow && (
          <InnerContainer>
            {renderPromoOnboardingHeader()}
            <InnerList>
              {promoOnboardingStepsToShow?.map(
                (step: string, index: number) => {
                  // registration and deposit steps (indexes 0 and 1) should be always completed
                  if (index <= 1) {
                    return (
                      <ListItemComplete>
                        <ListIconContainer>
                          <Icon name="success" size="s" />
                        </ListIconContainer>
                        {step}
                      </ListItemComplete>
                    );
                  }

                  return <ListItem>{step}</ListItem>;
                }
              )}
            </InnerList>
            <ButtonContainer>
              {promoOnboardingActions?.map((action, index, { length }) => {
                const { text, onClick, type } = action;

                return (
                  <Button
                    variant={type as ButtonVariant}
                    onClick={onClick}
                    qaLabel={`promo-onboarding-action-${text}`}
                    {...(index !== length - 1 && {
                      marginBottom: "space-3"
                    })}
                  >
                    {text}
                  </Button>
                );
              })}
            </ButtonContainer>
          </InnerContainer>
        )}
      {!isUserInPromoOnboarding &&
        !hasMatchingProposition &&
        actions.map(({ text, ...rest }) => (
          <ButtonStyled
            data-qa-label="message-panel-button"
            key={rest.qaLabel}
            {...rest}
            // @ts-ignore
            onClick={() =>
              onClickAction(
                hasBeenClicked,
                setHasBeenClicked,
                rest as ButtonAction
              )
            }
          >
            {text}
          </ButtonStyled>
        ))}
    </Container>
  );
};

export default MessagePanel;
