import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { get, attempt } from "lodash";

import {
  sortByLastUsed,
  getPaymentMethodIconName,
  isCardExpired,
  getTagsByPaymentType,
  isAccountUnderMaintenance
} from "@tvg/wallet/src/utils";
import {
  getAccountsOnFileMethods,
  getPaymentTypesUnderMaintenance,
  getPawsContent
} from "@tvg/sh-lib-paws/redux/selectors";
import { getMazoomaToggle } from "@tvg/sh-lib-paws/redux/toggles";
import { useLocation } from "react-router";
import Icon from "../../_static/Icons";
import { plus } from "../../_static/Icons/icons";
import {
  ButtonContainer,
  Button,
  Container,
  ScrollableContainer,
  PaymentSelectorWrapper
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import {
  getIdentifier,
  renderChkCard,
  selectButtonTextFromPaws
} from "./functions";
import usePaymentMethodsListActions from "./usePaymentMethodsListActions";

const PaymentMethodsList = ({
  device,
  isModal = true,
  isDeposit = false,
  isMobile = false,
  scrollToBottom,
  onCloseDepositModal,
  onChangePaymentMethod,
  otherMethods
}) => {
  const location = useLocation();
  const buttonText = useSelector(selectButtonTextFromPaws, shallowEqual);
  const accountsOnFile = useSelector(getAccountsOnFileMethods);
  const isMZMToggleOn = useSelector(getMazoomaToggle);
  const pawsIsNewTag = useSelector((store) =>
    get(store, "capi.featureToggles.pawsIsNewTag", false)
  );
  const paymentTypeTags = useSelector((store) =>
    attempt(() =>
      JSON.parse(get(store, "capi.messages.pawsPaymentTypeTags", ""))
    )
  );
  const paymentTypesUnderMaintenance = useSelector(
    getPaymentTypesUnderMaintenance
  );

  const { paymentMethodUnderMaintenance } = useSelector(getPawsContent);

  const scrollContainerRef = useRef();
  const [scrollMaxHeight, setScrollMaxHeight] = useState();
  const [btnShadow, setBtnShadow] = useState(false);

  const isWagerpad = location.pathname.includes("wagerpad");

  const {
    onClick,
    handleAddPaymentMethod,
    selectedMethod,
    handleAddPaymentFromWagerpad
  } = usePaymentMethodsListActions(
    isDeposit,
    scrollToBottom,
    onCloseDepositModal,
    onChangePaymentMethod
  );

  const areDepositsAvailable = (depositsAvailable) =>
    isDeposit && depositsAvailable;
  const areWithdrawalsAvailable = (withdrawalsAvailable) =>
    !isDeposit && withdrawalsAvailable;

  const onScroll = ({ target }) => {
    const scrollInTheEnd =
      target.offsetHeight + target.scrollTop === target.scrollHeight;
    setBtnShadow(!scrollInTheEnd);
  };

  useEffect(() => {
    if (!isMobile) {
      const paymentListMaxHeight =
        window.innerHeight -
        scrollContainerRef.current.getBoundingClientRect().top;

      setScrollMaxHeight(paymentListMaxHeight);
    }
  }, [isMobile]);

  useEffect(() => {
    const scrollEl = scrollContainerRef.current;
    setBtnShadow(scrollEl.scrollHeight > scrollEl.clientHeight);
  }, [scrollContainerRef.current]);

  const AddPaymentButton = useMemo(
    () => (
      <ButtonContainer shadow={btnShadow}>
        <Button
          onClick={
            isWagerpad ? handleAddPaymentFromWagerpad : handleAddPaymentMethod
          }
          to={isWagerpad ? undefined : "/wallet"}
          isModal={isModal}
        >
          {!isModal && (
            <Icon
              icon={plus}
              color={buildColor("blue_accent", "500")}
              size={16}
            />
          )}
          {buttonText}
        </Button>
      </ButtonContainer>
    ),
    [isModal, btnShadow]
  );

  return (
    <Container>
      <ScrollableContainer
        ref={scrollContainerRef}
        maxHeight={scrollMaxHeight}
        isMobile={isMobile}
        onScroll={onScroll}
      >
        {accountsOnFile
          .filter(
            (method) =>
              method.paymentType !== "SLP" &&
              (isMZMToggleOn || method.paymentType !== "MZM")
          )
          .sort((a, b) =>
            sortByLastUsed(a.lastUsedDateTime, b.lastUsedDateTime)
          )
          .concat(
            accountsOnFile.filter((method) => method.paymentType === "SLP")
          )
          .filter(
            (method) =>
              (areDepositsAvailable(method.depositsAvailable) ||
                areWithdrawalsAvailable(method.withdrawalsAvailable)) &&
              !isCardExpired(method.expirationDate)
          )
          .map((method, index) => {
            const { paymentType, title, description, id } = method;

            const identifier = getIdentifier(title, description);
            const qaLabel = `payment-selector-${paymentType}-${id}`;

            return (
              <PaymentSelectorWrapper
                hasDescription={!!description}
                key={`paymentSelector-${index + 1}`}
                variant={title.toLowerCase()}
                title={title}
                description={description}
                isSelected={selectedMethod === identifier}
                device={device}
                paymentType={paymentType}
                iconName={getPaymentMethodIconName(paymentType)}
                onClick={() => onClick(method, identifier)}
                qaLabel={qaLabel}
                pawsIsNewTag={pawsIsNewTag}
                tags={getTagsByPaymentType(paymentTypeTags, method.paymentType)}
                isUnderMaintenance={isAccountUnderMaintenance(
                  paymentTypesUnderMaintenance,
                  method
                )}
                underMaintenanceMessage={paymentMethodUnderMaintenance.message}
              />
            );
          })}
        {renderChkCard(
          !isDeposit,
          otherMethods,
          selectedMethod,
          device,
          (chkMethod, identifier) => onClick(chkMethod, identifier)
        )}
      </ScrollableContainer>
      {isDeposit ? AddPaymentButton : null}
    </Container>
  );
};

export default PaymentMethodsList;
