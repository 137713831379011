import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PaymentTypeItem } from "@tvg/wallet/src/types";
import { gtmPaymentSelectorOpen } from "@tvg/wallet/src/gtm";
import {
  getSelectedPaymentMethod,
  getPaymentMethodSelectorModal
} from "@tvg/sh-lib-paws/redux/selectors";
import {
  openPaymentMethodSelectorModal,
  closePaymentMethodSelectorModal
} from "@tvg/sh-lib-paws/redux/slices/paymentMethodSelectorSlice";
import PaymentButton from "../../_atom/PaymentButton";
import PaymentMethodModal from "../PaymentMethodModal";
import PaymentMethodsList from "../PaymentMethodsList";
import {
  Container,
  MethodListContainer,
  MethodListWrapper
} from "./styled-components";

const PaymentMethodSelector = ({
  device = "desktop",
  isModal = false,
  isDisabled = false,
  isDeposit = false,
  className,
  scrollToBottom,
  onCloseDepositModal,
  onChangePaymentMethod,
  otherMethods,
  onMouseDown
}) => {
  const { title, description, subTitle, paymentType } = useSelector(
    getSelectedPaymentMethod
  );
  const isOpen = useSelector(getPaymentMethodSelectorModal);
  const dispatch = useDispatch();

  const paymentButtonProps = !isModal ? { rotateArrow: isOpen ? -90 : 90 } : {};

  const handlePaymentButtonOnClick = useCallback(() => {
    if (!isDisabled) {
      if (isOpen) {
        dispatch(closePaymentMethodSelectorModal());
      } else {
        dispatch(openPaymentMethodSelectorModal());
      }
      gtmPaymentSelectorOpen({ currentPaymentType: paymentType });
    }
  }, [isOpen, paymentType, isDisabled]);

  return (
    <Container
      className={className}
      isDisabled={isDisabled}
      onMouseDown={onMouseDown}
    >
      <PaymentButton
        title={title}
        description={description}
        subTitle={subTitle}
        paymentType={paymentType}
        onClick={handlePaymentButtonOnClick}
        isDisabled={isDisabled}
        {...paymentButtonProps}
      />

      {isOpen && !isModal && (
        <MethodListContainer isDesktop={device !== "mobile"}>
          <MethodListWrapper isDesktop={device !== "mobile"}>
            <PaymentMethodsList
              device={device}
              isModal={isModal}
              isDeposit={isDeposit}
              onCloseDepositModal={onCloseDepositModal}
              scrollToBottom={scrollToBottom}
              onChangePaymentMethod={onChangePaymentMethod}
              otherMethods={otherMethods}
            />
          </MethodListWrapper>
        </MethodListContainer>
      )}

      {isOpen && isModal && (
        <PaymentMethodModal
          isModal={isModal}
          device={device}
          isOpen={isOpen}
          isDeposit={isDeposit}
          scrollToBottom={scrollToBottom}
          onChangePaymentMethod={onChangePaymentMethod}
          otherMethods={otherMethods}
        />
      )}
    </Container>
  );
};

export default PaymentMethodSelector;
