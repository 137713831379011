import React from "react";
import {
  getPawsOnlyIcons,
  getPaymentMethodIconName
} from "@tvg/wallet/src/utils";

import { noop } from "lodash/fp";
import {
  ButtonContainer,
  TextContainer,
  ButtonTitle,
  ButtonSubTitle,
  ButtonDescription,
  ArrowIconContainer
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { arrowRight } from "../../_static/Icons/icons";

import * as allIcons from "../../_static/Icons/icons";

const PaymentButton = ({
  icon,
  onClick = noop,
  isLink = false,
  url = "#",
  iconColor,
  title,
  subTitle,
  description,
  paymentType,
  rotateArrow = 0,
  isDisabled = false,
  className
}) => {
  const buttonProps = isLink ? { to: url } : { as: "button", onClick };
  const paymentIcon = getPaymentMethodIconName(paymentType);

  const capitalizeText = (text) =>
    text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return (
    <ButtonContainer
      data-qa-label="payment-button"
      className={className}
      isDisabled={isDisabled}
      {...buttonProps}
    >
      {getPawsOnlyIcons(
        paymentIcon,
        isDisabled ? buildColor("grey", "800") : undefined
      ) || (
        <Icon
          icon={icon || allIcons[paymentIcon]}
          size={16}
          color={
            isDisabled
              ? buildColor("grey", "800")
              : iconColor || buildColor("blue_accent", "500")
          }
        />
      )}
      <TextContainer>
        <ButtonTitle isDisabled={isDisabled}>
          {title && capitalizeText(title)}
          {!!subTitle && <ButtonSubTitle>{subTitle}</ButtonSubTitle>}
        </ButtonTitle>
        {!!description && <ButtonDescription>{description}</ButtonDescription>}
      </TextContainer>

      {!isDisabled && (
        <ArrowIconContainer rotateArrow={rotateArrow}>
          <Icon
            icon={arrowRight}
            size={16}
            color={buildColor("blue_accent", "500")}
          />
        </ArrowIconContainer>
      )}
    </ButtonContainer>
  );
};

export default PaymentButton;
