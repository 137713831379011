import React from "react";
import { noop } from "lodash";

import {
  KeyboardContainer,
  NumericButtonsContainer,
  NumericButton,
  MoneyPillsContainer,
  MoneyPillButton
} from "./styled-components";
import Icon from "../../_static/Icons";
import { deleteIcon } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

const CustomKeyboard = ({
  moneyPills,
  showMoneyPills,
  onChangeValue,
  onPeriod,
  onDelete,
  showPeriod,
  isNumbersDisabled,
  className,
  onClickMoneyPills
}) => {
  return (
    <KeyboardContainer data-qa-label="custom-keyboard" className={className}>
      <NumericButtonsContainer data-qa-label="custom-keyboard-numeric-buttons">
        {[...Array(9)].map((e, index) => (
          <NumericButton
            key={index.toString()}
            onClick={() => onChangeValue(index + 1, "mobile")}
            disabled={isNumbersDisabled}
            data-qa-label={`numeric-button-${index.toString()}`}
          >
            {index + 1}
          </NumericButton>
        ))}
        {showPeriod ? (
          <NumericButton onClick={onPeriod}>.</NumericButton>
        ) : (
          <NumericButton disabled />
        )}
        <NumericButton
          onClick={() => onChangeValue(0, "mobile")}
          disabled={isNumbersDisabled}
        >
          0
        </NumericButton>
        <NumericButton
          onClick={onDelete}
          data-qa-label="keyboard-delete-button"
        >
          <Icon
            icon={deleteIcon}
            size={24}
            viewBoxSize={24}
            color={buildColor("grey", "900")}
          />
        </NumericButton>
      </NumericButtonsContainer>
      {showMoneyPills && (
        <MoneyPillsContainer data-qa-label="custom-keyboard-money-pills">
          {moneyPills &&
            moneyPills.map((moneyPill, index) => (
              <MoneyPillButton
                key={index.toString()}
                onClick={() => {
                  onChangeValue(moneyPill, "moneyPills");
                  onClickMoneyPills();
                }}
                data-qa-label={`money-pill-button-${index.toString()}`}
              >
                <span>{`$${moneyPill}`}</span>
              </MoneyPillButton>
            ))}
        </MoneyPillsContainer>
      )}
    </KeyboardContainer>
  );
};

CustomKeyboard.defaultProps = {
  showMoneyPills: true,
  moneyPills: [50, 100, 200, 300],
  showPeriod: true,
  onPeriod: noop,
  onClickMoneyPills: noop,
  isNumbersDisabled: false
};

export default CustomKeyboard;
